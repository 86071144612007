<cjm-toaster
	[toasterType]="data?.toasterType"
	[toasterSubtitle]="data?.toasterTitle"
	[toasterMessage]="data?.toasterMessage"
	[toasterLinks]="data?.toasterLinks"
	[isClosable]="true"
	[hasBackgroundColor]="true"
	(closeButtonClicked)="closeMessage(data?.toasterId, data?.persistClosure)"
>
	@if (data?.loginButton?.title) {
		<div class="c-toaster__content__link--primary">
			<cjm-button [classes]="buttonClasses.LinkButton" [title]="data?.loginButton?.title" (click)="login()"
				>{{ data?.loginButton?.text }}
			</cjm-button>
		</div>
	}
</cjm-toaster>
