import { Component, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { AuthenticationService } from '@cjm/shared/authentication/auth';
import { ButtonClasses, ButtonComponent } from '@cjm/shared/ui/common';

import { SnackBarService } from '../../../data';
import { ToasterComponent } from '../toaster/toaster.component';
import { LoginToaster } from '../toaster/toaster.types';

@Component({
	templateUrl: './login-snack-bar.component.html',
	imports: [ToasterComponent, ButtonComponent]
})
export class LoginSnackBarComponent {
	@Input() public loginCallback: string;

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;

	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public readonly data: LoginToaster,
		private readonly snackBarService: SnackBarService,
		private readonly authService: AuthenticationService
	) {}

	/**
	 * login
	 *
	 * The login method is used to redirect the user to the login flow.
	 */
	public login(): void {
		// Redirect to login page with the provided callback
		this.authService
			.switch({
				customCallBack: this.loginCallback
			})
			.subscribe();
	}

	public closeMessage(id: string, persistClosure: 'session' | 'local'): void {
		this.snackBarService.dismiss(id, persistClosure);
	}
}
