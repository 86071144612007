@if (toasterTitle || toasterMessage) {
	<div
		class="c-toaster"
		[ngClass]="[toasterType, hasBackgroundColor ? 'has-background-color' : '', isClosable ? 'is-closable' : '']"
	>
		<div class="c-toaster__content">
			@if (toasterIcon || toasterSubtitle) {
				<div class="c-toaster__content__subtitle">
					@if (toasterIcon) {
						<fa-icon
							class="c-toaster__icon"
							[icon]="toasterIcon.icon"
							[iconType]="toasterIcon.type"
							[ngClass]="{ 'has-custom-icon': customToasterIcon }"
						/>
					}
					@if (toasterSubtitle) {
						<strong cypressTag="Toaster.Subtitle">
							{{ toasterSubtitle }}
						</strong>
					}
				</div>
			}
			@if (toasterTitle) {
				<p class="c-toaster__content__title" cypressTag="Toaster.Title">
					{{ toasterTitle }}
				</p>
			}
			@if (toasterMessage) {
				<p class="c-toaster__content__message" cypressTag="Toaster.Message" [innerHTML]="toasterMessage"></p>
			}
			<div class="c-toaster__content__links u-margin-top">
				@if (toasterLinks?.length > 0) {
					@for (toasterLink of toasterLinks; track toasterLink) {
						<cjm-link
							cypressTag="Toaster.Link"
							[classes]="'c-toaster__content__link c-button'"
							[ngClass]="{
								'c-toaster__content__link--primary': toasterLink.actionType === actionTypes.PRIMARY,
								'c-toaster__content__link--secondary': toasterLink.actionType === actionTypes.SECONDARY
							}"
							[type]="toasterLink.type"
							[to]="toasterLink.url"
							[title]="toasterLink.title"
							[icon]="toasterLink.icon"
							[keepInTab]="toasterLink.keepInTab"
							[noDecoration]="true"
						>
							{{ toasterLink.text }}
						</cjm-link>
					}
				}
				<ng-content></ng-content>
			</div>
		</div>
		@if (isClosable) {
			<cjm-button
				class="c-toaster__close-button"
				cypressTag="Toaster.Close"
				[classes]="buttonClasses.Link"
				[title]="i18nKeys.Common.Close | translate"
				(handleClick)="closeButtonClicked.emit()"
			>
				<fa-icon icon="fa-solid fa-xmark" />
			</cjm-button>
		}
	</div>
}
