import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { BrowserService, LocalStorageService } from '@cjm/shared/core';

// Denis: this service acts as a wrapper around the MatSnackBar service.
@Injectable({
	providedIn: 'root'
})
export class SnackBarService {
	private canDeactivate: boolean = false;

	constructor(
		private readonly snackBarService: MatSnackBar,
		private readonly browserService: BrowserService,
		private readonly localStorageService: LocalStorageService
	) {}

	/**
	 * openFromComponent
	 *
	 * The openFromComponent method will call the openFromComponent on the MatSnackBar instance with the provided arguments.
	 *
	 * @param component
	 * @param config
	 * @param canDeactivate
	 * @returns MatSnackBarRef
	 */
	public openFromComponent(
		component: ComponentType<unknown>,
		config: MatSnackBarConfig,
		canDeactivate: boolean = false
	): MatSnackBarRef<unknown> {
		this.canDeactivate = canDeactivate;

		if (!this.browserService.isBrowser) {
			return;
		}

		const toasterId = config?.data?.toasterId;

		if (toasterId && sessionStorage.getItem(`hide-${toasterId}-toaster`) === 'true') {
			return;
		}

		if (toasterId && this.localStorageService.getItem(`hide-${toasterId}-toaster`) === 'true') {
			return;
		}

		return this.snackBarService.openFromComponent(component, config);
	}

	/**
	 * dismiss
	 *
	 * The dismiss method will call the dismiss method on the MatSnackBar instance.
	 */
	public dismiss(id?: string, persistClosure?: 'session' | 'local'): void {
		if (id && persistClosure === 'session') {
			sessionStorage.setItem(`hide-${id}-toaster`, JSON.stringify(true));
		}

		if (id && persistClosure === 'local') {
			this.localStorageService.setItem(`hide-${id}-toaster`, JSON.stringify(true));
		}

		this.snackBarService.dismiss();
	}

	/**
	 * dismissOnDeactivate
	 *
	 * The dismissOnDeactivate will call the dismiss method on the MatSnackBar instance
	 * but will check if canDeactive has been set to true first.
	 *
	 * This method can be used to close toast messages from guards.
	 */
	public dismissOnDeactivate(): void {
		if (this.canDeactivate) {
			this.snackBarService.dismiss();
		}
	}
}
