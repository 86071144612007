import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@cjm/cypress/core';
import { sharedI18nKeys } from '@cjm/shared/i18n';
import {
	AlertType,
	AlertIcons,
	ButtonClasses,
	LinkType,
	VloketLinkIcon,
	ButtonComponent,
	FaIconComponent,
	LinkComponent
} from '@cjm/shared/ui/common';

import { ActionType } from './toaster.const';
import { ToasterLink } from './toaster.types';

@Component({
	selector: 'cjm-toaster',
	templateUrl: './toaster.component.html',
	styleUrls: ['./toaster.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, FaIconComponent, CypressTagDirective, LinkComponent, ButtonComponent, TranslateModule]
})
export class ToasterComponent implements OnInit {
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly alertTypes = AlertType;
	public readonly i18nKeys = sharedI18nKeys;
	public actionTypes: typeof ActionType = ActionType;
	public linkTypes: typeof LinkType = LinkType;
	public toasterIcon: VloketLinkIcon;

	/**
	 * Toaster type options
	 */
	@Input() public toasterType: AlertType = AlertType.Info;

	/**
	 * Custom icon for the toaster
	 */
	@Input() public customToasterIcon?: VloketLinkIcon;

	/**
	 * Title of the toaster
	 */
	@Input() public toasterTitle?: string;

	/**
	 * Subtitle of the toaster
	 */
	@Input() public toasterSubtitle?: string;

	/**
	 * Message of the toaster
	 */
	@Input() public toasterMessage: string;

	/**
	 * Links of the toaster
	 */
	@Input() public toasterLinks: ToasterLink[];

	/**
	 * Determines whether the toaster component can be closed or not. It is by default set to `true`.
	 * If it is set to false, then the toaster component will not have a close button and cannot be dismissed.
	 */
	@Input() public isClosable: boolean = true;

	/**
	 * Determines whether the toaster component will have a background color or not. It is by default set to `false`.
	 * If it is set to `true`, then the toaster component will have a background color according to its type.
	 */
	@Input() public hasBackgroundColor: boolean = false;

	/**
	 * Controls whether the toaster component displays an icon. It's `true` by default.
	 * The component shows the custom icon if available, or the default icon based on its type.
	 * When set to `false`, the icon is hidden.
	 */
	@Input() public disableIcon: boolean = false;

	/**
	 * Event fired when the close button is clicked on the toaster.
	 */
	@Output() public closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();

	private setToasterIcon = (): VloketLinkIcon => {
		if (this.disableIcon) return null;

		if (this.customToasterIcon) return this.customToasterIcon;

		return AlertIcons[this.toasterType];
	};

	public ngOnInit(): void {
		this.toasterIcon = this.setToasterIcon();
	}
}
