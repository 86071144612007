const k = e => !e || typeof e != "string" ? "" : e.match(/[A-Z][a-z]+/g).map((r, t) => t === 0 ? r : r.toLowerCase()).join(" ").trim(),
  w = e => typeof e != "string" ? e : e.replaceAll(/[\s\u00A0]/gi, " "),
  i = e => e.normalize("NFD").replace(/([\u0300-\u036f])/g, ""),
  F = (e, r, t = !0, n = !0) => b(e, r, t, n, "some"),
  C = (e, r, t = !0, n = !0) => b(e, r, t, n, "every"),
  b = (e, r, t = !0, n = !0, o) => {
    if (!r.trim()) return !0;
    if (!e) return !1;
    const c = r.split(" ").filter(s => !!s),
      O = i(e),
      a = s => {
        const u = t ? O : e,
          f = t ? i(s) : s;
        return n ? u.toLowerCase().includes(f.toLowerCase()) : u.includes(f);
      };
    return o === "some" ? c.some(a) : c.every(a);
  },
  S = (e, r) => {
    if (!e || Object.values(e).length === 0) return;
    const t = Object.values(e).indexOf(r);
    return Object.keys(e)[t];
  };
function h(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var j = e => {
  if (Object.prototype.toString.call(e) !== "[object Object]") return !1;
  const r = Object.getPrototypeOf(e);
  return r === null || r === Object.prototype;
};
const y = j,
  d = (e, r, t) => {
    let n = e;
    return r.cleanArrays === !0 && (n = e.map(o => l(o, r, t + 1)).filter(Boolean)), t === 0 || r.preserveArrays === !0 || n.length > 0 ? n : null;
  },
  l = (e, r, t) => {
    const n = {};
    if (!y(e)) return Array.isArray(e) ? d(e, r, t) : e;
    for (const o of Object.keys(e)) if (!(e[o] === null || e[o] === void 0)) if (Array.isArray(e[o])) {
      const c = d(e[o], r, t + 1);
      c !== null && (n[o] = c);
    } else if (y(e[o])) {
      const c = l(e[o], r, t + 1);
      c !== null && Object.keys(c).length > 0 && (n[o] = c);
    } else e[o] !== "" && (n[o] = e[o]);
    return t > 0 && Object.keys(n).length === 0 ? null : n;
  },
  g = (e, r) => {
    const t = {
      preserveArrays: !0,
      cleanArrays: !0,
      ...r
    };
    return l(e, t, 0);
  };
var m = g;
const A = /* @__PURE__ */h(m),
  V = (e, ...r) => r.reduce((t, n) => {
    const [o, c] = n;
    return c == null || typeof c == "object" && Object.keys(A(c)).length === 0 ? t : {
      ...t,
      [o]: c
    };
  },
  // Iben: Cast the start value to any to prevent issues with typing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e),
  p = (e, r) => {
    let t;
    return e.forEach(n => {
      t || (r(n) ? t = n : t = p(n.children, r));
    }), t;
  },
  E = (e, r) => {
    try {
      const t = URL.createObjectURL(e),
        n = document.createElement("a");
      n.download = r, n.href = t, n.click();
    } catch {
      throw new Error("Failed to transform blob to file");
    }
  };
export { k as camelCaseToSentence, S as getKeyByValue, V as merge, i as normalizeString, E as openFile, w as replaceHtmlWhitespace, p as searchRecursively, C as searchValueForEvery, F as searchValueForSome };